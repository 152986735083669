import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import {
  useTheme,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockJCVerReportGraph = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block_JC_Ver_report")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalJobCardsIssuedAll = data.reduce(
    (acc, curr) => acc + curr.no_of_job_cards_issued_all,
    0
  );
  const totalJobCardsIssuedActive = data.reduce(
    (acc, curr) => acc + curr.no_of_job_cards_issued_active,
    0
  );
  const totalJobCardsVerifiedAll = data.reduce(
    (acc, curr) => acc + curr.no_of_job_cards_verified_all,
    0
  );
  const totalJobCardsVerifiedActive = data.reduce(
    (acc, curr) => acc + curr.no_of_job_cards_verified_active,
    0
  );
  const percentageActiveIssued =
    (totalJobCardsIssuedActive / totalJobCardsIssuedAll) * 100 || 0;
  const percentageActiveVerified =
    (totalJobCardsVerifiedActive / totalJobCardsVerifiedAll) * 100 || 0;

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total Job Cards Issued
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalJobCardsIssuedAll}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Active Job Cards Issued
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalJobCardsIssuedActive}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total Job Cards Verified
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalJobCardsVerifiedAll}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Active Job Cards Verified
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalJobCardsVerifiedActive}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                % Active Issued
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {percentageActiveIssued.toFixed(2)}%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                % Active Verified
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {percentageActiveVerified.toFixed(2)}%
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Block Job Card Verification Report
          </Typography>
          <Box height={300}>
            <ResponsiveLine
              data={[
                {
                  id: "Total Job Cards Issued",
                  data: data.map((d) => ({
                    x: d.blocks,
                    y: d.no_of_job_cards_issued_all,
                  })),
                },
                {
                  id: "Active Job Cards Issued",
                  data: data.map((d) => ({
                    x: d.blocks,
                    y: d.no_of_job_cards_issued_active,
                  })),
                },
                {
                  id: "Total Job Cards Verified",
                  data: data.map((d) => ({
                    x: d.blocks,
                    y: d.no_of_job_cards_verified_all,
                  })),
                },
                {
                  id: "Active Job Cards Verified",
                  data: data.map((d) => ({
                    x: d.blocks,
                    y: d.no_of_job_cards_verified_active,
                  })),
                },
              ]}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              colors={{ scheme: "nivo" }}
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickValues: 5,
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -55,
                legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: -90,
                  translateY: -50,
                  itemsSpacing: 5,
                  itemDirection: "left-to-right",
                  itemWidth: 120,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>

        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Job Cards Verified
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                blocks: d.blocks,
                "Total Job Cards Verified": d.no_of_job_cards_verified_all,
                "Active Job Cards Verified": d.no_of_job_cards_verified_active,
              }))}
              keys={["Total Job Cards Verified", "Active Job Cards Verified"]}
              indexBy="blocks"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped" // Change from 'stacked' to 'grouped' to make bars side by side
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40, // Adjusted for better visibility
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: -90,
                  translateY: -50,
                  itemsSpacing: 5,
                  itemWidth: 120,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in blocks: ${e.indexValue}`
              }
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockJCVerReportGraph;
