import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { useTheme, Box, Typography, Card, CardContent } from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockTakenUpCompWrkDashboard = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block_takenup_compwrk")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalNoOfWorksStarted = data.reduce(
    (acc, curr) => acc + curr.total_no_of_works_started,
    0
  );
  const totalNoOfWorksCompleted = data.reduce(
    (acc, curr) => acc + curr.total_no_of_works_completed,
    0
  );
  const totalNotYetCompleted = data.reduce(
    (acc, curr) => acc + curr.total_not_yet_completed,
    0
  );
  const totalWorkCompletionRate =
    data.reduce((acc, curr) => acc + curr.total_work_completion_rate, 0) /
    data.length;

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Box display="flex" flexDirection="row" gap={2} mb={2}>
        <Card sx={{ bgcolor: colors.primary[400], flex: 1 }}>
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Total Works Started
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalNoOfWorksStarted}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ bgcolor: colors.primary[400], flex: 1 }}>
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Total Works Completed
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalNoOfWorksCompleted}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ bgcolor: colors.primary[400], flex: 1 }}>
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Not Yet Completed
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalNotYetCompleted}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ bgcolor: colors.primary[400], flex: 1 }}>
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Work Completion Rate (%)
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalWorkCompletionRate.toFixed(2)}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        {/* 2021-2022 */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Works Started and Completed (2021-2022)
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Works Started":
                  d.financial_year2021_2022and_earlier_no_of_works_started,
                "Works Completed":
                  d.financial_year2021_2022and_earlier_no_of_works_completed,
                "Not Yet Completed":
                  d.financial_year2021_2022and_earlier_not_yet_completed,
              }))}
              keys={["Works Started", "Works Completed", "Not Yet Completed"]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 250,
                  translateY: -40,
                  itemsSpacing: 95,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false}
            />
          </Box>
        </Box>

        {/* 2022-2023 */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Works Started and Completed
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Works Started": d.financial_year2022_2023_no_of_works_started,
                "Works Completed":
                  d.financial_year2022_2023_no_of_works_completed,
                "Not Yet Completed":
                  d.financial_year2022_2023_not_yet_completed,
              }))}
              keys={["Works Started", "Works Completed", "Not Yet Completed"]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 250,
                  translateY: -40,
                  itemsSpacing: 95,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false}
            />
          </Box>
        </Box>

        {/* 2023-2024 */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Works Started and Completed (2023-2024)
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Works Started": d.financial_year2023_2024_no_of_works_started,
                "Works Completed":
                  d.financial_year2023_2024_no_of_works_completed,
                "Not Yet Completed":
                  d.financial_year2023_2024_not_yet_completed,
              }))}
              keys={["Works Started", "Works Completed", "Not Yet Completed"]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 250,
                  translateY: -40,
                  itemsSpacing: 95,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false}
            />
          </Box>
        </Box>

        {/* 2024-2025 */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Works Started and Completed (2024-2025)
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Works Started": d.financial_year2024_2025_no_of_works_started,
                "Works Completed":
                  d.financial_year2024_2025_no_of_works_completed,
                "Not Yet Completed":
                  d.financial_year2024_2025_not_yet_completed,
              }))}
              keys={["Works Started", "Works Completed", "Not Yet Completed"]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 250,
                  translateY: -40,
                  itemsSpacing: 95,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false}
            />
          </Box>
        </Box>

        {/* Total Persondays - Pie Chart */}
        <Box display="flex" gap={4} width="100%">
          <Box
            p={2}
            bgcolor={colors.primary[400]}
            borderRadius="8px"
            flex={1}
            height={400}
          >
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Total Works Overview
            </Typography>
            <Box height="100%">
              <ResponsivePie
                data={[
                  { id: "Works Started", value: totalNoOfWorksStarted },
                  { id: "Works Completed", value: totalNoOfWorksCompleted },
                  { id: "Not Yet Completed", value: totalNotYetCompleted },
                ]}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "nivo" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor={colors.grey[100]}
                radialLabelsLinkColor={{ from: "color" }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor={colors.grey[100]}
                theme={{
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
              />
            </Box>
          </Box>

          {/* Total Persondays - Line Chart */}
          <Box
            p={2}
            bgcolor={colors.primary[400]}
            borderRadius="8px"
            flex={1}
            height={400}
          >
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Work Completion Rates
            </Typography>
            <Box height="100%">
              <ResponsiveLine
                data={[
                  {
                    id: "2021-2022",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.financial_year2021_2022and_earlier_work_completion_rate,
                    })),
                  },
                  {
                    id: "2022-2023",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.financial_year2022_2023_work_completion_rate,
                    })),
                  },
                  {
                    id: "2023-2024",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.financial_year2023_2024_work_completion_rate,
                    })),
                  },
                  {
                    id: "2024-2025",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.financial_year2024_2025_work_completion_rate,
                    })),
                  },
                  {
                    id: "Total",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.total_work_completion_rate,
                    })),
                  },
                ]}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: colors.grey[100],
                      },
                    },
                    legend: {
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
                colors={{ scheme: "nivo" }}
                margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 15,
                  legend: "Blocks",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Completion Rate (%)",
                  legendOffset: -50,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={8}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "top",
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 5,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockTakenUpCompWrkDashboard;
