import React, { useEffect, useState } from "react";
import axios from "./axiosConfig";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveBar } from "@nivo/bar";
import {
  useTheme,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";

const BlockFTODashboard = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block__FTO")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalFTOGenerated = data.reduce(
    (acc, curr) => acc + curr.total_fto_generated,
    0
  );
  const totalSignedFTOFirstSignatory = data.reduce(
    (acc, curr) => acc + curr.ist_signatory_no_of_signed_fto,
    0
  );
  const totalPendingFTOFirstSignatory = data.reduce(
    (acc, curr) => acc + curr.ist_signatory_no_of_fto_pending,
    0
  );
  const totalSignedFTOSecondSignatory = data.reduce(
    (acc, curr) => acc + curr["2nd_signatory_no_of_signed_fto"],
    0
  );
  const totalPendingFTOSecondSignatory = data.reduce(
    (acc, curr) => acc + curr["2nd_signatory_no_of_fto_pending"],
    0
  );
  const totalFTOSentToBank = data.reduce(
    (acc, curr) => acc + curr.fto_sent_to_bank_pfms_no_of_signed_fto,
    0
  );
  const totalTransactions = data.reduce(
    (acc, curr) => acc + curr.response_from_bank_pfms_total_transactions,
    0
  );

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card sx={{ bgcolor: colors.primary[400], height: "100%" }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total FTO Generated
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalFTOGenerated}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card sx={{ bgcolor: colors.primary[400], height: "100%" }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Signed FTO (1st Signatory)
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalSignedFTOFirstSignatory}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card sx={{ bgcolor: colors.primary[400], height: "100%" }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Pending FTO (1st Signatory)
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalPendingFTOFirstSignatory}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card sx={{ bgcolor: colors.primary[400], height: "100%" }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Signed FTO (2nd Signatory)
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalSignedFTOSecondSignatory}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Card sx={{ bgcolor: colors.primary[400], height: "100%" }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Pending FTO (2nd Signatory)
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalPendingFTOSecondSignatory}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        {/* FTO Generated and Signatories */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            FTO Generated and Signatories
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Total FTO Generated": d.total_fto_generated,
                "1st Signatory Signed FTO": d.ist_signatory_no_of_signed_fto,
                "1st Signatory Pending FTO": d.ist_signatory_no_of_fto_pending,
              }))}
              keys={[
                "Total FTO Generated",
                "1st Signatory Signed FTO",
                "1st Signatory Pending FTO",
              ]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 36,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "row",
                  justify: false,
                  translateX: -120,
                  translateY: -30,
                  itemsSpacing: 80,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false} // Disable horizontal grid lines
            />
          </Box>
        </Box>

        {/* 2nd Signatory FTO */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            2nd Signatory FTO
          </Typography>
          <Box height={300}>
            <ResponsivePie
              data={[
                { id: "Total FTO Generated", value: totalFTOGenerated },
                {
                  id: "2nd Signatory Signed FTO",
                  value: totalSignedFTOSecondSignatory,
                },
                {
                  id: "2nd Signatory Pending FTO",
                  value: totalPendingFTOSecondSignatory,
                },
              ]}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              colors={{ scheme: "nivo" }}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              radialLabelsSkipAngle={10}
              radialLabelsTextColor={colors.grey[100]}
              radialLabelsLinkColor={{ from: "color" }}
              sliceLabelsSkipAngle={10}
              sliceLabelsTextColor={colors.grey[100]}
              theme={{
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* Bank Processing */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Bank Processing
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "FTO Sent to Bank": d.fto_sent_to_bank_pfms_no_of_signed_fto,
                Transactions: d.fto_sent_to_bank_pfms_no_of_transaction,
                "Completely Processed":
                  d.fto_processed_by_bank_pfms_completely_no_,
                "Partially Processed":
                  d.fto_partially_processed_by_bank_pfms_no_,
                "Pending Processing":
                  d.fto_pending_for_processing_by_bank_pfms_no_,
              }))}
              keys={[
                "FTO Sent to Bank",
                "Transactions",
                "Completely Processed",
                "Partially Processed",
                "Pending Processing",
              ]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 36,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "row",
                  justify: false,
                  translateX: -120,
                  translateY: -30,
                  itemsSpacing: 50,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false} // Disable horizontal grid lines
            />
          </Box>
        </Box>

        {/* Invalid and Rejected Transactions */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Invalid and Rejected Transactions
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Invalid Accounts":
                  d.response_from_bank_pfms_no_of_invalid_account,
                "Rejected Transactions":
                  d.response_from_bank_pfms_no_of_transaction_rejected,
              }))}
              keys={["Invalid Accounts", "Rejected Transactions"]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 36,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "row",
                  justify: false,
                  translateX: -120,
                  translateY: -30,
                  itemsSpacing: 30,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false} // Disable horizontal grid lines
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockFTODashboard;
