import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import {
  useTheme,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockDpcSmsNewDashboard = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block_dpc_sms_new")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalGps = data.reduce((acc, curr) => acc + curr.total_no_of_gps, 0);
  const gpsWithWorksInProgress = data.reduce(
    (acc, curr) => acc + curr.no_of_gps_where_works_in_progress,
    0
  );
  const unskilledLabourEngaged = data.reduce(
    (acc, curr) =>
      acc +
      curr.of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_,
    0
  );

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total GPS
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalGps}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                GPS with Works in Progress
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {gpsWithWorksInProgress}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Unskilled Labour Expected
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {unskilledLabourEngaged}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Workers Without UID
          </Typography>
          <Box height={300}>
            <ResponsiveLine
              data={[
                {
                  id: "Workers Without UID",
                  data: data.map((d) => ({
                    x: d.blocks,
                    y: d.no_of_workers_without_uid,
                  })),
                },
              ]}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              colors={{ scheme: "nivo" }}
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickValues: 5,
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -50,
                legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: 80,
                  translateY: 0,
                  itemsSpacing: 5,
                  itemWidth: 120,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>

        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Muster Rolls
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                blocks: d.blocks,
                "Muster Rolls": d.no_of_muster_roll,
              }))}
              keys={["Muster Rolls"]}
              indexBy="blocks"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: 80,
                  translateY: 0,
                  itemsSpacing: 5,
                  itemWidth: 120,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in blocks: ${e.indexValue}`
              }
              enableGridY={false} // Disable horizontal grid lines
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Ongoing Works
            </Typography>
            <Box height={300}>
              <ResponsiveBar
                data={data.map((d) => ({
                  blocks: d.blocks,
                  "Ongoing Works": d.no_of_ongoing_works_on_which_mr_issued,
                }))}
                keys={["Ongoing Works"]}
                indexBy="blocks"
                margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                colors={{ scheme: "nivo" }}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: colors.grey[100],
                      },
                    },
                    legend: {
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Blocks",
                  legendPosition: "middle",
                  legendOffset: 40,
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Count",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 80,
                    translateY: 0,
                    itemsSpacing: 5,
                    itemWidth: 120,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  `${e.id}: ${e.formattedValue} in blocks: ${e.indexValue}`
                }
                enableGridY={false} // Disable horizontal grid lines
              />
            </Box>
          </Box>

          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Unskilled Labour Expected
            </Typography>
            <Box height={300}>
              <ResponsiveBar
                data={data.map((d) => ({
                  blocks: d.blocks,
                  "Unskilled Labour Expected":
                    d.of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_,
                }))}
                keys={["Unskilled Labour Expected"]}
                indexBy="blocks"
                margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                colors={{ scheme: "nivo" }}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: colors.grey[100],
                      },
                    },
                    legend: {
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Blocks",
                  legendPosition: "middle",
                  legendOffset: 40,
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Count",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 80,
                    translateY: 0,
                    itemsSpacing: 5,
                    itemWidth: 120,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  `${e.id}: ${e.formattedValue} in blocks: ${e.indexValue}`
                }
                enableGridY={false} // Disable horizontal grid lines
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockDpcSmsNewDashboard;
