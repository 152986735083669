import React, { useState, useEffect, useMemo } from "react";
import axios from "./axiosConfig";
import { ResponsiveBar } from "@nivo/bar";
import {
  useTheme,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Chip,
  Button,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import { tokens } from "../theme";

// Custom hook for fetching blocks
const useBlocks = () => {
  const [blocks, setBlocks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await axios.get("/blocks");
        setBlocks(response.data);
      } catch (error) {
        console.error("Error fetching blocks:", error);
        setError("Failed to fetch blocks. Please try again later.");
      }
    };

    fetchBlocks();
  }, []);

  return { blocks, error };
};

// Custom hook for fetching block data
const useBlockDpcSmsData = (selectedBlocks, startDate, endDate) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBlocks.length === 0 || !startDate || !endDate) return;

      setLoading(true);
      setError(null);

      try {
        const blockParams = selectedBlocks
          .map((b) => `block=${encodeURIComponent(b)}`)
          .join("&");
        const response = await axios.get(
          `/dataa/block_dpc_sms_new?${blockParams}&start_date=${startDate}&end_date=${endDate}`
        );

        if (Array.isArray(response.data) && response.data.length > 0) {
          const formattedData = response.data.reduce((acc, item) => {
            const existingDate = acc.find((d) => d.date === item.date);
            if (existingDate) {
              existingDate[`${item.blocks}_labour`] =
                item.of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_;
              existingDate[`${item.blocks}_ongoing`] =
                item.no_of_ongoing_works_on_which_mr_issued;
              existingDate[`${item.blocks}_muster`] = item.no_of_muster_roll;
            } else {
              acc.push({
                date: item.date,
                [`${item.blocks}_labour`]:
                  item.of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_,
                [`${item.blocks}_ongoing`]:
                  item.no_of_ongoing_works_on_which_mr_issued,
                [`${item.blocks}_muster`]: item.no_of_muster_roll,
              });
            }
            return acc;
          }, []);
          setData(formattedData);
        } else {
          setError("No data available for the selected criteria.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          `Failed to fetch data: ${
            error.response?.data?.error || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedBlocks, startDate, endDate]);

  return { data, loading, error };
};

const BlockDpcSmsDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { blocks, error: blocksError } = useBlocks();
  const {
    data,
    loading,
    error: dataError,
  } = useBlockDpcSmsData(selectedBlocks, startDate, endDate);

  // Set initial dates
  useEffect(() => {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - 7);
    setEndDate(end.toISOString().split("T")[0]);
    setStartDate(start.toISOString().split("T")[0]);
  }, []);

  // Memoize color scheme
  const colorScheme = useMemo(() => ["#ff6b6b", "#4ecdc4", "#45b7d1"], []);

  const handleBlockChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedBlocks(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemoveBlock = (blockToRemove) => {
    setSelectedBlocks((prev) =>
      prev.filter((block) => block !== blockToRemove)
    );
  };

  const handleReset = () => {
    setSelectedBlocks([]);
  };

  const getKeys = (type) => {
    return selectedBlocks.map((block) => `${block}_${type}`);
  };

  const BarChart = ({
    data,
    keys,
    indexBy,
    yAxisLegend,
    colorScheme,
    title,
  }) => (
    <Box height="350px" position="relative">
      <Typography
        variant="h6"
        component="div"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          color: colors.grey[100],
          padding: "5px 10px",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 1,
          color: colors.grey[100],
          padding: "5px 10px",
        }}
      >
        {yAxisLegend}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 60,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          zIndex: 1,
        }}
      >
        {keys.map((key, index) => (
          <Box
            key={key}
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <Box
              sx={{
                width: 20,
                height: 20,
                backgroundColor: colorScheme[index % colorScheme.length],
                marginRight: "5px",
              }}
            />
            <Typography variant="caption" sx={{ color: colors.grey[100] }}>
              {key.split("_")[0]}
            </Typography>
          </Box>
        ))}
      </Box>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={colorScheme}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Date",
          legendPosition: "middle",
          legendOffset: 32,
          legendTextColor: colors.grey[100],
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: yAxisLegend,
          legendPosition: "middle",
          legendOffset: -40,
          legendTextColor: colors.grey[100],
        }}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={"#000000"}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          tooltip: {
            container: {
              background: colors.primary[400],
            },
          },
        }}
        tooltip={({ id, value, color }) => (
          <strong
            style={{
              color: colors.grey[100],
              background: colors.primary[400],
              padding: "5px",
              borderRadius: "3px",
            }}
          >
            {id.split("_")[0]}: {value}
          </strong>
        )}
      />
    </Box>
  );

  return (
    <Box m="20px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        Block-Wise NREGA E-Muster Daily
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]} mb={4}>
        Unskilled Labour Engagement
      </Typography>

      <Paper
        elevation={3}
        sx={{
          p: "20px",
          borderRadius: "15px",
          bgcolor: colors.primary[400],
          mb: 4,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="block-multiple-checkbox-label">Blocks</InputLabel>
              <Select
                labelId="block-multiple-checkbox-label"
                id="block-multiple-checkbox"
                multiple
                value={selectedBlocks}
                onChange={handleBlockChange}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => handleRemoveBlock(value)}
                        sx={{
                          bgcolor: colors.greenAccent[700],
                          color: colors.grey[100],
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {blocks.map((block) => (
                  <MenuItem key={block} value={block}>
                    <Checkbox checked={selectedBlocks.indexOf(block) > -1} />
                    <ListItemText primary={block} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleReset}
              sx={{
                bgcolor: colors.blueAccent[700],
                color: colors.grey[100],
                "&:hover": {
                  bgcolor: colors.blueAccent[800],
                },
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {(blocksError || dataError) && (
        <Box mt={4}>
          <Typography color="error">{blocksError || dataError}</Typography>
        </Box>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {!loading && !dataError && data.length > 0 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, bgcolor: colors.primary[400], height: "400px" }}>
              <BarChart
                data={data}
                keys={getKeys("labour")}
                indexBy="date"
                yAxisLegend="Labour Count"
                colorScheme={colorScheme}
                title="Unskilled Labour Engagement"
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, bgcolor: colors.primary[400], height: "400px" }}>
              <BarChart
                data={data}
                keys={getKeys("ongoing")}
                indexBy="date"
                yAxisLegend="Work Count"
                colorScheme={colorScheme}
                title="Ongoing Works"
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, bgcolor: colors.primary[400], height: "400px" }}>
              <BarChart
                data={data}
                keys={getKeys("muster")}
                indexBy="date"
                yAxisLegend="Muster Roll Count"
                colorScheme={colorScheme}
                title="Muster Rolls"
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BlockDpcSmsDashboard;
