import React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import { tokens } from "../../theme";

const tables = [
  {
    title: "Job Cards Verification",
    path: "/jc-ver-report",
    icon: <TimelineOutlinedIcon />,
  },
  { title: "NMMS", path: "/nmms-report", icon: <TimelineOutlinedIcon /> },
  {
    title: "DEM Register",
    path: "/dem-register",
    icon: <TimelineOutlinedIcon />,
  },
  { title: "DPC Report", path: "/dpc-sms-new", icon: <TimelineOutlinedIcon /> },
  {
    title: "Incomplete Works",
    path: "/incomplete-works",
    icon: <TimelineOutlinedIcon />,
  },
  {
    title: "Take Up Work Since 21",
    path: "/take-up-work",
    icon: <TimelineOutlinedIcon />,
  },
  {
    title: "Categorywise Work",
    path: "/categorywise-work",
    icon: <TimelineOutlinedIcon />,
  },
  { title: "FTO", path: "/fto", icon: <TimelineOutlinedIcon /> },
  {
    title: "Account Verification",
    path: "/account-verification",
    icon: <TimelineOutlinedIcon />,
  },
  {
    title: "Table Display",
    path: "/table_display",
    icon: <TimelineOutlinedIcon />,
  },
];

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  return (
    <Box m="20px">
      <Typography variant="h4" fontWeight="600" color={colors.grey[100]} mb={3}>
        Dashboard
      </Typography>

      <Box display="flex" flexWrap="wrap" gap="20px">
        {tables.map((table, index) => (
          <Box
            key={index}
            width="200px"
            height="200px"
            bgcolor={colors.primary[400]}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px"
            onClick={() => navigate(table.path)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                backgroundColor: colors.primary[300],
                transform: "scale(1.05)",
              },
              transition: "transform 0.2s",
            }}
          >
            <IconButton color="inherit" sx={{ fontSize: "50px" }}>
              {table.icon}
            </IconButton>
            <Typography variant="h6" color={colors.grey[100]} mt={1}>
              {table.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Dashboard;
