import React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme"; // Update this path according to your project structure

const PowerBIReport = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" height="100%">
      <iframe
        title="Power BI Report"
        width="100%"
        height="800px"
        src="https://app.powerbi.com/view?r=eyJrIjoiZTMyMmZjOWQtNDljMS00ZWNhLTlkOTUtMTU4ZWJlMTVmY2UyIiwidCI6ImNkNzkwNzk3LWY4MTQtNDM4Ny05NDRlLTE4MWY3OTk2YTNkYyJ9&pageName=ReportSection9537b01e0929b2746c2e"
        frameBorder="0"
        allowFullScreen={true}
      ></iframe>
    </Box>
  );
};

export default PowerBIReport;
