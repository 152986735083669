import { useState, useCallback } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import BlockJCVerReportGraph from "./components/BlockJCVerReportGraph";
import BlockDemRegisterDashboard from "./components/BlockDemRegisterDashboard";
import BlockNMMSReportGraph from "./components/BlockNMMSReportGraph";
import BlockDpcSmsNewDashboard from "./components/BlockDpcSmsNewDashboard";
import BlockIncompleteWorksDashboard from "./components/BlockIncompleteWorksDashboard";
import BlockTakenUpCompWrkDashboard from "./components/BlockTakenUpCompWrkDashboard";
import BlockWorkCatwiseExpDashboard from "./components/BlockWorkCatwiseExpDashboard";
import BlockFTODashboard from "./components/BlockFTODashboard";
import BlockAccountVerificationStatusDashboard from "./components/BlockAccountVerificationStatusDashboard";
import TableDisplay from "./components/TableDisplay";
import PowerBIReport from "./components/CMhelpline";
import DistrictReport from "./components/DistrictWiseReport";
import DepartmentReport from "./components/DepartmentPerformance";
import NregaEMusterDailyDashboard from "./components/NregaEMusterDailyDashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import BlockDpcSmsDashboard from "./components/BlockDpcSmsDashboard";
import PanchayatDashboard1 from "./components/PanchayatDashboard1";

import "./App.css";

const tableRoutes = [
  "/jc-ver-report",
  "/nmms-report",
  "/dem-register",
  "/dpc-sms-new",
  "/incomplete-works",
  "/take-up-work",
  "/categorywise-work",
  "/fto",
  "/account-verification",
  "/table_display",
  "/nrega-e-muster-daily",
  "/panchayat-dashboard",
];

function App() {
  const [theme, colorMode] = useMode();
  const [selectedDate, setSelectedDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const currentIndex = tableRoutes.indexOf(currentPath);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
  }, []);

  const handleNext = () => {
    if (currentIndex < tableRoutes.length - 1) {
      navigate(tableRoutes[currentIndex + 1]);
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      navigate(tableRoutes[currentIndex - 1]);
    }
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar isCollapsed={isCollapsed} />
          <div className="main-content">
            <Topbar
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              onSearch={handleSearch}
              onNext={handleNext}
              onBack={handleBack}
              toggleSidebar={toggleSidebar}
              isCollapsed={isCollapsed}
            />
            <main className="content">
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/jc-ver-report"
                  element={
                    <BlockJCVerReportGraph selectedDate={selectedDate} />
                  }
                />
                <Route
                  path="/nmms-report"
                  element={<BlockNMMSReportGraph selectedDate={selectedDate} />}
                />
                <Route
                  path="/dem-register"
                  element={
                    <BlockDemRegisterDashboard selectedDate={selectedDate} />
                  }
                />
                <Route
                  path="/dpc-sms-new"
                  element={
                    <BlockDpcSmsNewDashboard selectedDate={selectedDate} />
                  }
                />
                <Route
                  path="/incomplete-works"
                  element={
                    <BlockIncompleteWorksDashboard
                      selectedDate={selectedDate}
                    />
                  }
                />
                <Route
                  path="/take-up-work"
                  element={
                    <BlockTakenUpCompWrkDashboard selectedDate={selectedDate} />
                  }
                />
                <Route
                  path="/categorywise-work"
                  element={
                    <BlockWorkCatwiseExpDashboard selectedDate={selectedDate} />
                  }
                />
                <Route
                  path="/fto"
                  element={<BlockFTODashboard selectedDate={selectedDate} />}
                />
                <Route
                  path="/account-verification"
                  element={
                    <BlockAccountVerificationStatusDashboard
                      selectedDate={selectedDate}
                    />
                  }
                />
                <Route
                  path="/block-dpc-sms-new"
                  element={<BlockDpcSmsDashboard />}
                />
                <Route
                  path="/table_display"
                  element={<TableDisplay selectedDate={selectedDate} />}
                />
                <Route
                  path="/panchayat-dashboard"
                  element={<PanchayatDashboard1 selectedDate={selectedDate} />}
                />
                <Route
                  path="/nrega-e-muster-daily"
                  element={<NregaEMusterDailyDashboard />}
                />
                <Route path="/powerbi-report" element={<PowerBIReport />} />
                <Route path="/district-report" element={<DistrictReport />} />
                <Route
                  path="/department-report"
                  element={<DepartmentReport />}
                />
              </Routes>
            </main>
          </div>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
