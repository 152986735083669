import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import {
  useTheme,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockNMMSReportGraph = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (selectedDate) {
      axios
        .get("/data/block_NMMS")
        .then((response) => {
          const filteredData = response.data.filter(
            (item) =>
              new Date(item.date).toISOString().split("T")[0] === selectedDate
          );
          setData(filteredData);
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        });
    }
  }, [selectedDate]);

  const totalGPsHavingWorks = data.reduce(
    (acc, curr) => acc + curr.no_of_gps_having_works_with_over20_workers,
    0
  );
  const totalWorksHavingOver20Workers = data.reduce(
    (acc, curr) => acc + curr.no_of_works_having_over20_workers,
    0
  );

  const barData = data.map((d) => ({
    blocks: d.blocks,
    "GPs Having Works": d.no_of_gps_having_works_with_over20_workers,
    "Works Having Over 20 Workers": d.no_of_works_having_over20_workers,
  }));

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total GPs Having Works with Over 20 Workers
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalGPsHavingWorks}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total Works Having Over 20 Workers
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalWorksHavingOver20Workers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Bar Chart */}
      <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
        <Typography
          variant="h6"
          component="div"
          mb={2}
          color={colors.grey[100]}
        >
          Block NMMS Report
        </Typography>
        <Box height={400}>
          <ResponsiveBar
            data={barData}
            keys={["GPs Having Works", "Works Having Over 20 Workers"]}
            indexBy="blocks"
            margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
            padding={0.3}
            groupMode="grouped"
            colors={{ scheme: "nivo" }}
            theme={{
              axis: {
                domain: {
                  line: {
                    stroke: colors.grey[100],
                  },
                },
                legend: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                ticks: {
                  line: {
                    stroke: colors.grey[100],
                    strokeWidth: 1,
                  },
                  text: {
                    fill: colors.grey[100],
                  },
                },
              },
              legends: {
                text: {
                  fill: colors.grey[100],
                },
              },
              tooltip: {
                container: {
                  color: colors.primary[500],
                },
              },
            }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Blocks",
              legendPosition: "middle",
              legendOffset: 40,
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-right",
                direction: "row",
                justify: false,
                translateX: -80,
                translateY: -20,
                itemsSpacing: 25,
                itemWidth: 120,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            role="application"
            ariaLabel="Nivo bar chart demo"
            barAriaLabel={(e) =>
              `${e.id}: ${e.formattedValue} in blocks: ${e.indexValue}`
            }
            enableGridY={false} // Disable horizontal grid lines
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BlockNMMSReportGraph;
