import React from "react";
import {
  Box,
  IconButton,
  Tooltip,
  useTheme,
  Divider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PowerIcon from "@mui/icons-material/Power";

const Sidebar = ({ isCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const SidebarIcon = ({ title, icon, to }) => (
    <Tooltip title={title} placement="right">
      <IconButton
        component={Link}
        to={to}
        sx={{
          "&:hover": {
            transform: "scale(1.2)",
            transition: "transform 0.2s",
          },
          color: colors.grey[100],
        }}
      >
        {icon}
        {!isCollapsed && (
          <Typography variant="body2" ml={1}>
            {title}
          </Typography>
        )}
      </IconButton>
    </Tooltip>
  );

  return (
    <Box
      sx={{
        backgroundColor: colors.primary[400],
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: isCollapsed ? "center" : "flex-start",
        width: isCollapsed ? "60px" : "200px",
        pt: "10px",
        transition: "width 0.2s",
        position: "relative",
        overflowY: "auto",
      }}
    >
      {/* Profile Icon */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt="12px"
        mb={2}
      >
        <img
          src="/219291.JPEG"
          alt="profile"
          width={isCollapsed ? "30px" : "80px"}
          height={isCollapsed ? "30px" : "80px"}
          style={{ borderRadius: "50%", transition: "width 0.3s, height 0.3s" }}
        />
      </Box>

      {/* Divider */}
      <Divider sx={{ width: "100%", mb: 4, bgcolor: colors.grey[700] }} />

      {/* Rural Development Heading */}
      {!isCollapsed && (
        <Typography variant="h6" color={colors.grey[100]} pl={2} mb={1}>
          Rural Development
        </Typography>
      )}

      {/* Rural Development Icons */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isCollapsed ? "center" : "flex-start"}
        gap={2}
        pl={isCollapsed ? 0 : 2}
      >
        <SidebarIcon
          title="Job Cards Verification"
          icon={<TimelineOutlinedIcon />}
          to="/jc-ver-report"
        />
        <SidebarIcon
          title="NMMS"
          icon={<AssessmentOutlinedIcon />}
          to="/nmms-report"
        />
        <SidebarIcon
          title="DEM Register"
          icon={<BarChartOutlinedIcon />}
          to="/dem-register"
        />
        <SidebarIcon
          title="DPC Report"
          icon={<PieChartOutlineOutlinedIcon />}
          to="/dpc-sms-new"
        />
        <SidebarIcon
          title="Incomplete Works"
          icon={<CategoryOutlinedIcon />}
          to="/incomplete-works"
        />
        <SidebarIcon
          title="Take Up Work"
          icon={<TimelineOutlinedIcon />}
          to="/take-up-work"
        />
        <SidebarIcon
          title="Categorywise Work"
          icon={<TableChartOutlinedIcon />}
          to="/categorywise-work"
        />
        <SidebarIcon title="FTO" icon={<VerifiedOutlinedIcon />} to="/fto" />
        <SidebarIcon
          title="Account Verification"
          icon={<TimelineOutlinedIcon />}
          to="/account-verification"
        />
        <SidebarIcon
          title="Panchayat Labour"
          icon={<TimelineOutlinedIcon />}
          to="/nrega-e-muster-daily"
        />
        <SidebarIcon
          title="Top Panchayat"
          icon={<TimelineOutlinedIcon />}
          to="/panchayat-dashboard"
        />
        <SidebarIcon
          title="Block Labour"
          icon={<TimelineOutlinedIcon />}
          to="/block-dpc-sms-new"
        />
        <SidebarIcon
          title="Table Display"
          icon={<TableChartOutlinedIcon />}
          to="/table_display"
        />
      </Box>

      {/* Divider */}
      <Divider sx={{ width: "100%", my: 2, bgcolor: colors.grey[700] }} />

      {/* CM Helpline Heading */}
      {!isCollapsed && (
        <Typography variant="h6" color={colors.grey[100]} pl={2} mb={1}>
          CM Helpline
        </Typography>
      )}

      {/* CM Helpline Icons */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems={isCollapsed ? "center" : "flex-start"}
        gap={2}
        pl={isCollapsed ? 0 : 2}
      >
        <SidebarIcon
          title="CM Helpline Report"
          icon={<PowerIcon />}
          to="/powerbi-report"
        />
        <SidebarIcon
          title="District Wise Performance"
          icon={<PowerIcon />}
          to="/district-report"
        />
        <SidebarIcon
          title="Department Performance"
          icon={<PowerIcon />}
          to="/department-report"
        />
      </Box>

      {/* Divider */}
      <Divider sx={{ width: "0%", my: 20, bgcolor: colors.grey[700] }} />
    </Box>
  );
};

export default Sidebar;
