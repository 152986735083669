import React, { useEffect, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveRadar } from "@nivo/radar";
import { ResponsiveLine } from "@nivo/line";
import {
  Box,
  Typography,
  Card,
  CardContent,
  useTheme,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockAccountVerificationStatusDashboard = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block_account_verification_status")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalPendingNewlyAdded = data.reduce(
    (acc, curr) =>
      acc + curr.number_of_accounts_pending_for_validation_by_pfms_newly_added,
    0
  );
  const totalPendingUpdatedAfterRejection = data.reduce(
    (acc, curr) =>
      acc +
      curr._accounts_pending_for_validation_by_pfms_updated_after_rejection,
    0
  );
  const totalPendingAccounts = data.reduce(
    (acc, curr) =>
      acc + curr.number_of_accounts_pending_for_validation_by_pfms_total,
    0
  );

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Accounts Pending (Newly Added)
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalPendingNewlyAdded}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Accounts Pending (Updated After Rejection)
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalPendingUpdatedAfterRejection}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total Pending Accounts
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalPendingAccounts}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        {/* Bar Chart */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Accounts Pending
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Pending (Newly Added)":
                  d.number_of_accounts_pending_for_validation_by_pfms_newly_added,
                "Pending (Updated After Rejection)":
                  d._accounts_pending_for_validation_by_pfms_updated_after_rejection,
                "Total Pending":
                  d.number_of_accounts_pending_for_validation_by_pfms_total,
              }))}
              keys={[
                "Pending (Newly Added)",
                "Pending (Updated After Rejection)",
                "Total Pending",
              ]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 36,
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 120,
                  translateY: -40,
                  itemsSpacing: 120,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false} // Disable horizontal grid lines
            />
          </Box>
        </Box>

        {/* Radar Chart */}
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Accounts Verification
          </Typography>
          <Box height={300}>
            <ResponsiveRadar
              data={data.map((d) => ({
                block: d.block,
                "Pending (Newly Added)":
                  d.number_of_accounts_pending_for_validation_by_pfms_newly_added,
                "Pending (Updated After Rejection)":
                  d._accounts_pending_for_validation_by_pfms_updated_after_rejection,
                "Total Pending":
                  d.number_of_accounts_pending_for_validation_by_pfms_total,
              }))}
              keys={[
                "Pending (Newly Added)",
                "Pending (Updated After Rejection)",
                "Total Pending",
              ]}
              indexBy="block"
              margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              colors={{ scheme: "nivo" }}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              gridLabelOffset={36}
              dotSize={10}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              dotBorderColor={{ from: "color" }}
              motionConfig="wobbly"
              legends={[
                {
                  anchor: "top-left",
                  direction: "column",
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemTextColor: colors.grey[100],
                  symbolSize: 12,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: colors.primary[500],
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>

        {/* Pie Chart */}
        <Box display="flex" gap={4}>
          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Accounts Pending Distribution
            </Typography>
            <Box height={300}>
              <ResponsivePie
                data={[
                  { id: "Newly Added", value: totalPendingNewlyAdded },
                  {
                    id: "Updated After Rejection",
                    value: totalPendingUpdatedAfterRejection,
                  },
                  { id: "Total Pending", value: totalPendingAccounts },
                ]}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "nivo" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor={colors.grey[100]}
                radialLabelsLinkColor={{ from: "color" }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor={colors.grey[100]}
                theme={{
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
              />
            </Box>
          </Box>

          {/* Line Chart */}
          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Accounts Validation Over Time
            </Typography>
            <Box height={300}>
              <ResponsiveLine
                data={[
                  {
                    id: "Pending (Newly Added)",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.number_of_accounts_pending_for_validation_by_pfms_newly_added,
                    })),
                  },
                  {
                    id: "Pending (Updated After Rejection)",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d._accounts_pending_for_validation_by_pfms_updated_after_rejection,
                    })),
                  },
                  {
                    id: "Total Pending",
                    data: data.map((d) => ({
                      x: d.block,
                      y: d.number_of_accounts_pending_for_validation_by_pfms_total,
                    })),
                  },
                ]}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: colors.grey[100],
                      },
                    },
                    legend: {
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
                colors={{ scheme: "nivo" }}
                margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                yFormat=" >-.2f"
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 20,
                  legend: "Blocks",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickValues: 5,
                  tickSize: 3,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Count",
                  legendOffset: -55,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={8}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 80,
                    translateY: -10,
                    itemsSpacing: 5,
                    itemDirection: "left-to-right",
                    itemWidth: 120,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: "circle",
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockAccountVerificationStatusDashboard;
