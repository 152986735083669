import React, { useState, useEffect, useMemo } from "react";
import axios from "./axiosConfig";
import { ResponsiveBar } from "@nivo/bar";
import {
  useTheme,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Chip,
  Button,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import { tokens } from "../theme";

// Custom hook for fetching panchayats
const usePanchayats = () => {
  const [panchayats, setPanchayats] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPanchayats = async () => {
      try {
        const response = await axios.get("/panchayats");
        setPanchayats(response.data);
      } catch (error) {
        console.error("Error fetching panchayats:", error);
        setError("Failed to fetch panchayats. Please try again later.");
      }
    };

    fetchPanchayats();
  }, []);

  return { panchayats, error };
};

// Custom hook for fetching data
const useNregaData = (selectedPanchayats, startDate, endDate) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedPanchayats.length === 0 || !startDate || !endDate) return;

      setLoading(true);
      setError(null);

      try {
        const panchayatParams = selectedPanchayats
          .map((p) => `panchayat=${encodeURIComponent(p)}`)
          .join("&");
        const response = await axios.get(
          `/data/nrega_e_muster_daily?${panchayatParams}&start_date=${startDate}&end_date=${endDate}`
        );

        const formattedData = response.data.reduce((acc, item) => {
          const existingDate = acc.find((d) => d.date === item.date);
          if (existingDate) {
            existingDate[`${item.panchayats}_labour`] =
              item.of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_;
            existingDate[`${item.panchayats}_ongoing`] =
              item.no_of_ongoing_works_on_which_mr_issued;
            existingDate[`${item.panchayats}_muster`] = item.no_of_muster_roll;
          } else {
            acc.push({
              date: item.date,
              [`${item.panchayats}_labour`]:
                item.of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_,
              [`${item.panchayats}_ongoing`]:
                item.no_of_ongoing_works_on_which_mr_issued,
              [`${item.panchayats}_muster`]: item.no_of_muster_roll,
            });
          }
          return acc;
        }, []);

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedPanchayats, startDate, endDate]);

  return { data, loading, error };
};

const NregaEMusterDailyDashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedPanchayats, setSelectedPanchayats] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { panchayats, error: panchayatsError } = usePanchayats();
  const {
    data,
    loading,
    error: dataError,
  } = useNregaData(selectedPanchayats, startDate, endDate);

  // Set initial dates
  useEffect(() => {
    const end = new Date();
    const start = new Date();
    start.setDate(end.getDate() - 7);
    setEndDate(end.toISOString().split("T")[0]);
    setStartDate(start.toISOString().split("T")[0]);
  }, []);

  // Memoize color scheme
  const panchayatColors = useMemo(
    () => [
      "#ff6b6b",
      "#4ecdc4",
      "#45b7d1",
      "#feca57",
      "#ff9ff3",
      "#54a0ff",
      "#5f27cd",
      "#48dbfb",
      "#ff6b6b",
      "#4ecdc4",
    ],
    []
  );

  const handlePanchayatChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedPanchayats(typeof value === "string" ? value.split(",") : value);
  };

  const handleRemovePanchayat = (panchayatToRemove) => {
    setSelectedPanchayats((prev) =>
      prev.filter((panchayat) => panchayat !== panchayatToRemove)
    );
  };

  const handleReset = () => {
    setSelectedPanchayats([]);
  };

  const getKeys = (type) => {
    return selectedPanchayats.map((panchayat) => `${panchayat}_${type}`);
  };

  const BarChart = ({ data, keys, indexBy, yAxisLegend, title }) => (
    <Box height="350px" position="relative">
      <Typography
        variant="h6"
        component="div"
        sx={{
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: 1,
          color: colors.grey[100],
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="div"
        sx={{
          position: "absolute",
          bottom: 5,
          right: 10,
          zIndex: 1,
          color: colors.grey[300],
        }}
      >
        {yAxisLegend}
      </Typography>
      <Box
        sx={{
          position: "absolute",
          top: 30,
          right: 60,
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          zIndex: 1,
        }}
      >
        {keys.map((key, index) => (
          <Box
            key={key}
            sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
          >
            <Box
              sx={{
                width: 12,
                height: 12,
                backgroundColor:
                  panchayatColors[index % panchayatColors.length],
                marginRight: "5px",
              }}
            />
            <Typography variant="caption" sx={{ color: colors.grey[100] }}>
              {key.split("_")[0]}
            </Typography>
          </Box>
        ))}
      </Box>
      <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={indexBy}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={({ id }) =>
          panchayatColors[keys.indexOf(id) % panchayatColors.length]
        }
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Date",
          legendPosition: "middle",
          legendOffset: 32,
          legendTextColor: colors.grey[100],
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          legendTextColor: colors.grey[100],
        }}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={"#ffffff"}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: colors.grey[100],
              },
            },
            legend: {
              text: {
                fill: colors.grey[100],
              },
            },
            ticks: {
              line: {
                stroke: colors.grey[100],
                strokeWidth: 1,
              },
              text: {
                fill: colors.grey[100],
              },
            },
          },
          tooltip: {
            container: {
              background: colors.primary[400],
            },
          },
        }}
        tooltip={({ id, value, color }) => (
          <strong
            style={{
              color: colors.grey[100],
              background: colors.primary[400],
              padding: "5px",
              borderRadius: "3px",
            }}
          >
            {id.split("_")[0]}: {value}
          </strong>
        )}
      />
    </Box>
  );

  return (
    <Box m="20px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "5px" }}
      >
        NREGA E-Muster Daily Dashboard
      </Typography>
      <Typography variant="h5" color={colors.greenAccent[400]} mb={4}>
        Unskilled Labour Engagement
      </Typography>

      <Paper
        elevation={3}
        sx={{
          p: "20px",
          borderRadius: "15px",
          bgcolor: colors.primary[400],
          mb: 4,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="panchayat-multiple-checkbox-label">
                Panchayats
              </InputLabel>
              <Select
                labelId="panchayat-multiple-checkbox-label"
                id="panchayat-multiple-checkbox"
                multiple
                value={selectedPanchayats}
                onChange={handlePanchayatChange}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => handleRemovePanchayat(value)}
                        sx={{
                          bgcolor: colors.greenAccent[700],
                          color: colors.grey[100],
                        }}
                      />
                    ))}
                  </Box>
                )}
              >
                {panchayats.map((panchayat) => (
                  <MenuItem key={panchayat} value={panchayat}>
                    <Checkbox
                      checked={selectedPanchayats.indexOf(panchayat) > -1}
                    />
                    <ListItemText primary={panchayat} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              variant="contained"
              onClick={handleReset}
              sx={{
                bgcolor: colors.blueAccent[700],
                color: colors.grey[100],
                "&:hover": {
                  bgcolor: colors.blueAccent[800],
                },
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {(panchayatsError || dataError) && (
        <Box mt={4}>
          <Typography color="error">{panchayatsError || dataError}</Typography>
        </Box>
      )}

      {loading && (
        <Box display="flex" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      {!loading && !dataError && data.length > 0 && (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, bgcolor: colors.primary[400], height: "400px" }}>
              <BarChart
                data={data}
                keys={getKeys("labour")}
                indexBy="date"
                yAxisLegend="Labour Count"
                title="Unskilled Labour Engagement"
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, bgcolor: colors.primary[400], height: "400px" }}>
              <BarChart
                data={data}
                keys={getKeys("ongoing")}
                indexBy="date"
                yAxisLegend="Work Count"
                title="Ongoing Works"
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 3, bgcolor: colors.primary[400], height: "400px" }}>
              <BarChart
                data={data}
                keys={getKeys("muster")}
                indexBy="date"
                yAxisLegend="Muster Roll Count"
                title="Muster Rolls"
              />
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default NregaEMusterDailyDashboard;
