import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
import axios from "./axiosConfig";

// Mapping technical table names to user-friendly names
const tableNames = {
  account_info: "Account Info",
  account_verification_status: "Account Verification Status",
  amrit_sarovar: "Amrit Sarovar",
  asset_id: "Asset ID",
  bank_disbursal: "Bank Disbursal",
  block_Gis_based_work: "Block GIS Based Work",
  block_Gis_based_work_New: "Block GIS Based Work New",
  block_JC_Ver_report: "Block JC Verification Report",
  block_NMMS: "Block NMMS",
  block__FTO: "Block FTO",
  block_acc_info_dbt: "Block Account Info DBT",
  block_account_verification_status: "Block Account Verification Status",
  block_amrit_sarovar_rpt: "Block Amrit Sarovar Report",
  block_assets_report: "Block Assets Report",
  block_bank_disbursal: "Block Bank Disbursal",
  block_demregister: "Block DEM Register",
  block_dpc_sms_new: "Block DPC SMS New",
  block_executive_agency_report: "Block Executive Agency Report",
  block_geo_mnrega: "Block Geo MNREGA",
  block_incomplete_works_Yearwise: "Block Incomplete Works Yearwise",
  block_lb_mon_rptippe: "Block LB MON RPT IPPE",
  block_mis_bank_post: "Block MIS Bank Post",
  block_nil_exp: "Block NIL Expenditure",
  block_takenup_compwrk: "Block Taken Up Comp Work",
  block_well_progress: "Block Well Progress",
  block_work_catwise_exp: "Block Work Catwise Expenditure",
  convergence_work_MIS: "Convergence Work MIS",
  employment_generated_during_year: "Employment Generated During Year",
  incomplete_works: "Incomplete Works",
  nrega_e_muster_daily: "NREGA e-Muster Daily",
  outlay_income: "Outlay Income",
  over_20_workers_gp: "Over 20 Workers GP",
  well_progress: "Well Progress",
  work_completion_percentage: "Work Completion Percentage",
  work_status: "Work Status",
};

const TableDisplay = ({ selectedDate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTable, setSelectedTable] = useState("");
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  const handleTableChange = (event) => {
    setSelectedTable(event.target.value);
  };

  useEffect(() => {
    if (selectedTable && selectedDate) {
      axios
        .get(`/data/${selectedTable}`)
        .then((response) => {
          const filteredData = response.data.filter(
            (item) =>
              new Date(item.date).toISOString().split("T")[0] === selectedDate
          );
          setData(filteredData);

          if (filteredData.length > 0) {
            const cols = Object.keys(filteredData[0]).map((key) => ({
              field: key,
              headerName: key.charAt(0).toUpperCase() + key.slice(1),
              flex: 1,
            }));
            setColumns(cols);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
        });
    }
  }, [selectedTable, selectedDate]);

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={2}
        bgcolor={colors.primary[400]}
        p={2}
        borderRadius="8px"
        mb={4}
      >
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel id="table-select-label" sx={{ color: colors.grey[100] }}>
            Table
          </InputLabel>
          <Select
            labelId="table-select-label"
            value={selectedTable}
            onChange={handleTableChange}
            label="Table"
            sx={{
              color: colors.grey[100],
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: colors.grey[100],
              },
            }}
          >
            {Object.entries(tableNames).map(([key, name]) => (
              <MenuItem key={key} value={key}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box mt={4} bgcolor={colors.primary[400]} p={2} borderRadius="8px">
        <Typography variant="h6" color={colors.grey[100]} mb={2}>
          Data for "{tableNames[selectedTable]}" for Date "{selectedDate}"
        </Typography>
        <Box height="600px">
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) =>
              row.id || Math.random().toString(36).substr(2, 9)
            }
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            sx={{
              border: `1px solid ${colors.grey[100]}`,
              color: colors.grey[100],
              "& .MuiDataGrid-cell": {
                borderBottom: `1px solid ${colors.grey[800]}`,
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.primary[700],
                color: colors.grey[100],
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: `1px solid ${colors.grey[800]}`,
                backgroundColor: colors.primary[700],
              },
              "& .MuiDataGrid-toolbarContainer": {
                color: colors.grey[100],
                "& .MuiButton-text": {
                  color: `${colors.grey[100]} !important`,
                },
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TableDisplay;
