import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveRadar } from "@nivo/radar";
import { useTheme, Box, Typography, Card, CardContent } from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockWorkCatwiseExpDashboard = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block_work_catwise_exp")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalLabour = data.reduce((acc, curr) => acc + curr.labour, 0);
  const totalOnMaterial = data.reduce((acc, curr) => acc + curr.on_material, 0);
  const totalExpenditure = data.reduce((acc, curr) => acc + curr.total, 0);
  const totalConvergenceLabour = data.reduce(
    (acc, curr) => acc + curr.expenditure_under_convergence_in_lakhs_labour,
    0
  );
  const totalConvergenceMaterial = data.reduce(
    (acc, curr) =>
      acc + curr.expenditure_under_convergence_in_lakhs_on_material,
    0
  );
  const totalConvergence = data.reduce(
    (acc, curr) => acc + curr.expenditure_under_convergence_in_lakhs_total,
    0
  );

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Box display="flex" flexDirection="row" gap={2} mb={2} flexWrap="wrap">
        <Card
          sx={{
            bgcolor: colors.primary[400],
            flex: 1,
            minWidth: "200px",
            mb: 2,
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Total Labour Expenditure (Lakhs)
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalLabour}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            bgcolor: colors.primary[400],
            flex: 1,
            minWidth: "200px",
            mb: 2,
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Total Material Expenditure (Lakhs)
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalOnMaterial}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            bgcolor: colors.primary[400],
            flex: 1,
            minWidth: "200px",
            mb: 2,
          }}
        >
          <CardContent>
            <Typography variant="h6" component="div" color={colors.grey[100]}>
              Total Expenditure (Lakhs)
            </Typography>
            <Typography variant="h4" component="div" color={colors.grey[100]}>
              {totalExpenditure}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Expenditure by Category (Lakhs)
          </Typography>
          <Box height={300}>
            <ResponsiveLine
              data={[
                {
                  id: "Labour",
                  data: data.map((d) => ({ x: d.block, y: d.labour })),
                },
                {
                  id: "Material",
                  data: data.map((d) => ({ x: d.block, y: d.on_material })),
                },
                {
                  id: "Total",
                  data: data.map((d) => ({ x: d.block, y: d.total })),
                },
              ]}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              colors={{ scheme: "nivo" }}
              margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickValues: 5,
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Expenditure",
                legendOffset: -50,
                legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "top", // Changed to top
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: -40,
                  itemsSpacing: 10,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>

        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Convergence Expenditure (Lakhs)
          </Typography>
          <Box height={400}>
            <ResponsiveRadar
              data={data.map((d) => ({
                block: d.block,
                labour: d.expenditure_under_convergence_in_lakhs_labour,
                material: d.expenditure_under_convergence_in_lakhs_on_material,
                total: d.expenditure_under_convergence_in_lakhs_total,
              }))}
              keys={["labour", "material", "total"]}
              indexBy="block"
              margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              colors={{ scheme: "nivo" }}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              gridLabelOffset={36}
              dotSize={10}
              dotColor={{ theme: "background" }}
              dotBorderWidth={2}
              dotBorderColor={{ from: "color" }}
              motionConfig="wobbly"
              legends={[
                {
                  anchor: "top-left",
                  direction: "column",
                  translateX: -50,
                  translateY: -40,
                  itemWidth: 80,
                  itemHeight: 20,
                  itemTextColor: colors.grey[100],
                  symbolSize: 12,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: colors.primary[500],
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={4} width="100%">
          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Labour vs Material Expenditure (Lakhs)
            </Typography>
            <Box height={400}>
              <ResponsivePie
                data={[
                  { id: "Labour", value: totalLabour },
                  { id: "Material", value: totalOnMaterial },
                ]}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "nivo" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor={colors.grey[100]}
                radialLabelsLinkColor={{ from: "color" }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor={colors.grey[100]}
                theme={{
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Total Expenditure (Lakhs)
            </Typography>
            <Box height={400}>
              <ResponsiveBar
                data={data.map((d) => ({
                  block: d.block,
                  Labour: d.labour,
                  Material: d.on_material,
                  Total: d.total,
                }))}
                keys={["Labour", "Material", "Total"]}
                indexBy="block"
                margin={{ top: 50, right: 50, bottom: 70, left: 60 }}
                padding={0.3}
                groupMode="grouped"
                colors={{ scheme: "nivo" }}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: colors.grey[100],
                      },
                    },
                    legend: {
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 15,
                  legend: "Blocks",
                  legendPosition: "middle",
                  legendOffset: 40,
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Expenditure",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
                enableGridX={false}
                enableGridY={false}
                pointSize={8}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "top", // Changed to top
                    direction: "row",
                    justify: false,
                    translateX: 0,
                    translateY: -40,
                    itemsSpacing: 10,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
                }
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockWorkCatwiseExpDashboard;
