import React, { useContext, useEffect } from "react";
import {
  Box,
  IconButton,
  useTheme,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { Link } from "react-router-dom";

const Topbar = ({
  selectedDate,
  setSelectedDate,
  toggleSidebar,
  isCollapsed,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0];
    setSelectedDate(formattedDate);
  }, [setSelectedDate]);

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      borderBottom={`1px solid ${colors.grey[300]}`}
      backgroundColor={colors.primary[400]}
      width="100%"
    >
      <Grid container alignItems="center" justifyContent="space-between">
        {/* LEFT ICONS */}
        <Grid item>
          <Box display="flex" gap={1} alignItems="center">
            <IconButton onClick={toggleSidebar}>
              <MenuOutlinedIcon style={{ color: colors.grey[100] }} />
            </IconButton>
            <IconButton component={Link} to="/">
              <HomeOutlinedIcon style={{ color: colors.grey[100] }} />
            </IconButton>
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlinedIcon style={{ color: colors.grey[100] }} />
              ) : (
                <LightModeOutlinedIcon style={{ color: colors.grey[100] }} />
              )}
            </IconButton>
          </Box>
        </Grid>
        {/* TITLE */}
        <Grid item>
          <Box textAlign="center">
            <Typography
              variant="h4"
              style={{ color: colors.grey[100], fontWeight: 600 }}
            >
              Rajgarh District Dashboard
            </Typography>
          </Box>
        </Grid>
        {/* DATE SELECTOR */}
        <Grid item>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ marginLeft: "20px" }}
          >
            <TextField
              type="date"
              value={selectedDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
                style: { color: colors.grey[100] },
              }}
              sx={{
                "& .MuiInputBase-root": {
                  bgcolor: colors.primary[400],
                  borderRadius: 1,
                },
                "& .MuiInputBase-input": {
                  color: colors.grey[100],
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: colors.grey[100],
                  },
                  "&:hover fieldset": {
                    borderColor: colors.grey[100],
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: colors.grey[100],
                  },
                },
                width: "150px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Topbar;
