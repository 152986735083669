import React, { useState, useEffect } from "react";
import axios from "./axiosConfig";
import { ResponsiveBar } from "@nivo/bar";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { tokens } from "../theme";
import { useTheme } from "@mui/material/styles";

const PanchayatDashboard1 = ({ selectedDate }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [n, setN] = useState(10);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [n, selectedDate]);

  const fetchData = async () => {
    try {
      console.log("Fetching data for date:", selectedDate);
      const response = await axios.get(
        `/data/top_panchayats?n=${n}&date=${selectedDate}`
      );
      setData(response.data);
      console.log("Fetched data:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData(null);
    }
  };

  const BarChart = ({ data, keys, indexBy, label }) => {
    if (!data || data.length === 0) {
      return (
        <Typography color={colors.grey[100]}>
          No data available for the selected date.
        </Typography>
      );
    }

    return (
      <Box height={400}>
        <ResponsiveBar
          data={data}
          keys={[keys]}
          indexBy="panchayats"
          margin={{ top: 50, right: 130, bottom: 100, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={[
            "#8dd3c7",
            "#ffffb3",
            "#bebada",
            "#fb8072",
            "#80b1d3",
            "#fdb462",
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Panchayats",
            legendPosition: "middle",
            legendOffset: 80,
            textColor: colors.grey[100],
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: label,
            legendPosition: "middle",
            legendOffset: -40,
            textColor: colors.grey[100],
          }}
          enableGridY={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="#000000"
          label={(d) => `${d.value}`}
          labelPosition="top"
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
            },
          }}
        />
      </Box>
    );
  };

  const ZeroTable = ({ data, title }) => (
    <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
              }}
            >
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((panchayat, index) => (
            <TableRow key={index}>
              <TableCell>{panchayat}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box m="20px">
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{ mb: "20px" }}
      >
        Panchayat Performance Dashboard
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        p={2}
        bgcolor={colors.primary[400]}
        borderRadius="50px"
      >
        <TextField
          type="number"
          label="Top N Panchayats"
          value={n}
          onChange={(e) => setN(e.target.value)}
          sx={{ mr: 2 }}
          InputLabelProps={{ style: { color: colors.grey[100] } }}
          InputProps={{ style: { color: colors.grey[100] } }}
        />
        <Typography color={colors.grey[100]}>
          Selected Date: {selectedDate}
        </Typography>
        <Button variant="contained" onClick={fetchData}>
          Fetch Data
        </Button>
      </Box>

      {data && (
        <>
          <Box mb={4}>
            <Typography variant="h4" mb={2} color={colors.grey[100]}>
              Key Performance Indicators
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: colors.primary[400] }}>
                  <CardContent>
                    <Typography variant="h6" color={colors.grey[100]}>
                      Panchayats with Zero Unskilled Labour
                    </Typography>
                    <Typography variant="h3" color={colors.greenAccent[500]}>
                      {data.zero_labour_count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: colors.primary[400] }}>
                  <CardContent>
                    <Typography variant="h6" color={colors.grey[100]}>
                      Panchayats with Zero Ongoing Works
                    </Typography>
                    <Typography variant="h3" color={colors.greenAccent[500]}>
                      {data.zero_works_count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card sx={{ bgcolor: colors.primary[400] }}>
                  <CardContent>
                    <Typography variant="h6" color={colors.grey[100]}>
                      Panchayats with Zero Muster Rolls
                    </Typography>
                    <Typography variant="h3" color={colors.greenAccent[500]}>
                      {data.zero_muster_count}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={4} direction="column">
            <Grid item xs={12}>
              <Card sx={{ bgcolor: colors.primary[400] }}>
                <CardContent>
                  <Typography variant="h5" mb={2} color={colors.grey[100]}>
                    Top Panchayats - Unskilled Labour Engagement
                  </Typography>
                  <BarChart
                    data={data.top_labour}
                    keys="of_unskilled_labour_expected_to_be_engaged_as_per_e_muster_roll_"
                    indexBy="panchayats"
                    label="Unskilled Labour"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ bgcolor: colors.primary[400] }}>
                <CardContent>
                  <Typography variant="h5" mb={2} color={colors.grey[100]}>
                    Top Panchayats - Ongoing Works
                  </Typography>
                  <BarChart
                    data={data.top_works}
                    keys="no_of_ongoing_works_on_which_mr_issued"
                    indexBy="panchayats"
                    label="Ongoing Works"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ bgcolor: colors.primary[400] }}>
                <CardContent>
                  <Typography variant="h5" mb={2} color={colors.grey[100]}>
                    Top Panchayats - Muster Rolls
                  </Typography>
                  <BarChart
                    data={data.top_muster}
                    keys="no_of_muster_roll"
                    indexBy="panchayats"
                    label="Muster Rolls"
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography variant="h4" mb={2} color={colors.grey[100]}>
              Panchayats with Zero Values
            </Typography>
            <Box
              sx={{
                maxHeight: 450,
                overflow: "auto",
                bgcolor: colors.primary[400],
                p: 2,
                borderRadius: "8px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <ZeroTable
                    data={data.zero_labour}
                    title="Zero Unskilled Labour"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ZeroTable
                    data={data.zero_works}
                    title="Zero Ongoing Works"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <ZeroTable
                    data={data.zero_muster}
                    title="Zero Muster Rolls"
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PanchayatDashboard1;
