import React, { useEffect, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import {
  useTheme,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";
import { tokens } from "../theme";
import axios from "./axiosConfig";

const BlockDemRegisterDashboard = ({ selectedDate }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    axios
      .get("/data/block_demregister")
      .then((response) => {
        const filteredData = response.data.filter(
          (item) =>
            new Date(item.date).toISOString().split("T")[0] === selectedDate
        );
        setData(filteredData);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [selectedDate]);

  const totalRegisteredHouseholds = data.reduce(
    (acc, curr) => acc + curr.no_of_registered_household,
    0
  );
  const totalRegisteredPersons = data.reduce(
    (acc, curr) => acc + curr.no_of_registered_persons,
    0
  );
  const totalEmploymentDemandedHouseholds = data.reduce(
    (acc, curr) => acc + curr.employment_demanded_household,
    0
  );
  const totalEmploymentDemandedPersons = data.reduce(
    (acc, curr) => acc + curr.employment_demanded_persons,
    0
  );
  const totalEmploymentAvailedPersondays = data.reduce(
    (acc, curr) => acc + curr.employment_availed_total_persondays,
    0
  );

  return (
    <Box display="flex" flexDirection="column" p={2} gap={2}>
      {/* KPIs */}
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total Registered Households
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalRegisteredHouseholds}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Total Registered Persons
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalRegisteredPersons}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Employment Demanded Households
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalEmploymentDemandedHouseholds}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ bgcolor: colors.primary[400] }}>
            <CardContent>
              <Typography variant="h6" component="div" color={colors.grey[100]}>
                Employment Demanded Persons
              </Typography>
              <Typography variant="h4" component="div" color={colors.grey[100]}>
                {totalEmploymentDemandedPersons}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Charts */}
      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Employment Demand
          </Typography>
          <Box height={300}>
            <ResponsiveLine
              data={[
                {
                  id: "Employment Demanded Households",
                  data: data.map((d) => ({
                    x: d.block,
                    y: d.employment_demanded_household,
                  })),
                },
                {
                  id: "Employment Demanded Persons",
                  data: data.map((d) => ({
                    x: d.block,
                    y: d.employment_demanded_persons,
                  })),
                },
              ]}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              colors={{ scheme: "nivo" }}
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 0,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickValues: 5,
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendOffset: -50,
                legendPosition: "middle",
              }}
              enableGridX={false}
              enableGridY={false}
              pointSize={8}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "top", // Move legend to the top
                  direction: "row", // Change direction to row
                  justify: false,
                  translateX: 0,
                  translateY: -40, // Adjust position to top
                  itemsSpacing: 120,
                  itemDirection: "left-to-right",
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Household Issued Job Cards
            </Typography>
            <Box height={300}>
              <ResponsivePie
                data={[
                  {
                    id: "SC",
                    value: data.reduce(
                      (acc, curr) =>
                        acc + curr.cumulative_no_of_hh_issued_jobcards_scs,
                      0
                    ),
                  },
                  {
                    id: "ST",
                    value: data.reduce(
                      (acc, curr) =>
                        acc + curr.cumulative_no_of_hh_issued_jobcards_sts,
                      0
                    ),
                  },
                  {
                    id: "Others",
                    value: data.reduce(
                      (acc, curr) =>
                        acc + curr.cumulative_no_of_hh_issued_jobcards_others,
                      0
                    ),
                  },
                  {
                    id: "Total",
                    value: data.reduce(
                      (acc, curr) =>
                        acc + curr.cumulative_no_of_hh_issued_jobcards_total,
                      0
                    ),
                  },
                ]}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                colors={{ scheme: "nivo" }}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor={colors.primary[500]} // Change to desired label color
                radialLabelsLinkColor={{ from: "color" }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor={colors.primary[100]} // Change to desired label color
                theme={{
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
              />
            </Box>
          </Box>

          <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px" flex={1}>
            <Typography
              variant="h6"
              component="div"
              mb={2}
              color={colors.grey[100]}
            >
              Total Persondays
            </Typography>
            <Box height={300}>
              <ResponsiveBar
                data={data.map((d) => ({
                  block: d.block,
                  "Employment Availed Persondays":
                    d.employment_availed_total_persondays,
                }))}
                keys={["Employment Availed Persondays"]}
                indexBy="block"
                margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
                padding={0.3}
                colors={{ scheme: "nivo" }}
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: colors.grey[100],
                      },
                    },
                    legend: {
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                    ticks: {
                      line: {
                        stroke: colors.grey[100],
                        strokeWidth: 1,
                      },
                      text: {
                        fill: colors.grey[100],
                      },
                    },
                  },
                  legends: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  tooltip: {
                    container: {
                      color: colors.primary[500],
                    },
                  },
                }}
                borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 40,
                  legend: "Blocks",
                  legendPosition: "middle",
                  legendOffset: 40, // Adjusted for better visibility
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Persondays",
                  legendPosition: "middle",
                  legendOffset: -55,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "top", // Change to 'top' to move legend to the top
                    direction: "row", // Change direction to 'row' for horizontal alignment
                    justify: false,
                    translateX: 0,
                    translateY: -40, // Adjust translateY to position it closer to the chart
                    itemsSpacing: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
                }
                enableGridY={false} // Disable horizontal grid lines
              />
            </Box>
          </Box>
        </Box>

        <Box p={2} bgcolor={colors.primary[400]} borderRadius="8px">
          <Typography
            variant="h6"
            component="div"
            mb={2}
            color={colors.grey[100]}
          >
            Employment Offered
          </Typography>
          <Box height={300}>
            <ResponsiveBar
              data={data.map((d) => ({
                block: d.block,
                "Employment Offered Households": d.employment_offered_household,
                "Employment Offered Persons": d.employment_offered_persons,
              }))}
              keys={[
                "Employment Offered Households",
                "Employment Offered Persons",
              ]}
              indexBy="block"
              margin={{ top: 50, right: 150, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped" // Change from 'stacked' to 'grouped' to make bars side by side
              colors={{ scheme: "nivo" }}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: colors.grey[100],
                    },
                  },
                  legend: {
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                  ticks: {
                    line: {
                      stroke: colors.grey[100],
                      strokeWidth: 1,
                    },
                    text: {
                      fill: colors.grey[100],
                    },
                  },
                },
                legends: {
                  text: {
                    fill: colors.grey[100],
                  },
                },
                tooltip: {
                  container: {
                    color: colors.primary[500],
                  },
                },
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Blocks",
                legendPosition: "middle",
                legendOffset: 40, // Adjusted for better visibility
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Count",
                legendPosition: "middle",
                legendOffset: -50,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "top",
                  direction: "row",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 100,
                  itemWidth: 120,
                  itemHeight: -40,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                `${e.id}: ${e.formattedValue} in block: ${e.indexValue}`
              }
              enableGridY={false} // Disable horizontal grid lines
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlockDemRegisterDashboard;
